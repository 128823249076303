<template>
  <section
    class="container relative bg-white bg-center bg-cover card h-full w-10/12 z-20 lg:w-full pt-10 pb-4 lg:h-64 lg:py-0"
  >
    <div v-if="$route.name === 'index'">
      <h5
        class="absolute -top-60 left-0 right-0 md:-top-55 xl:-top-60 md:left-15 xl:left-14 xxl:left-17-9 text-secondary font-link lg:w-2/12 xxl:w-1/12 text-center"
      >
        Probeer jouw ideale koppeling
      </h5>
      <div class="line button btn-round absolute -top-12 left-42 sm:left-46-5 lg:left-20-5 hover:opacity-100">
        <font-awesome-icon :icon="['fas', 'long-arrow-alt-down']"/>
      </div>
    </div>
    <div v-if="$route.name === 'sectoren-id'">
      <h3
        class="absolute left-0 md:left-23-5 -top-50 sm:-top-40 lg:-top-40 xxl:left-23-5 text-secondary text-lg lg:text-2xl md:text-center"
      >
        Jouw ideale koppeling van programma’s
      </h3>
      <p
        class="absolute left-0 md:left-23-5 -top-26 xxl:left-23-5 font-semi text-white leading-relaxed md:leading-none md:text-center"
      >
        Leg de link, bekijk of wij het voor je kunnen bouwen!
      </p>
      <div class="hidden lg:flex line button btn-round absolute -top-12 left-20-5 hover:opacity-100">
        <font-awesome-icon :icon="['fas', 'long-arrow-alt-down']"/>
      </div>
    </div>
    <div class="flex flex-col justify-center h-full">
      <div class="hidden lg:grid grid-cols-12 pb-6">
        <h3 class="col-start-2 col-end-8">
          Bekijk of jouw ideale koppeling beschikbaar is
        </h3>
      </div>
      <div class="grid grid-cols-12">
        <div class="col-start-2 col-end-12 md:col-end-6 lg:col-start-2 xl:col-end-5">
          <v-select
            v-model="selectedOne"
            :options="productListOne"
            label="title"
            placeholder="Zoek systeem"
            :clearable="false"
            class="select h-full"
          >
            <template v-slot:option="option">
              <div class="flex items-center h-full">
                <span>{{ option.title }}</span>
                <img v-if="option.favicon" :src="option.favicon" :alt="option.title" class="-mt-1 ml-3 object-contain">
              </div>
            </template>
            <template v-slot:no-options>Sorry, neem contact op</template>
          </v-select>
        </div>
        <div
          class="flex justify-center items-center text-sm lg:text-2xl col-start-6 col-end-8 md:col-end-7 xl:col-start-5 lg:col-end-6 py-3 lg:py-0"
        >
          <font-awesome-icon :icon="['far', 'link']" size="lg"/>
        </div>
        <div class="col-start-2 col-end-12 md:col-start-7 md:col-end-11 xl:col-start-6 xl:col-end-9">
          <v-select
            v-model="selectedTwo"
            :options="productListTwo"
            label="title"
            placeholder="Zoek systeem"
            :clearable="false"
            class="select h-full"
          >
            <template v-slot:option="option">
              <div class="flex items-center h-full">
                <span>{{ option.title }}</span>
                <img v-if="option.favicon" :src="option.favicon" :alt="option.title" class="-mt-1 ml-3 object-contain">
              </div>
            </template>
            <template v-slot:no-options>Sorry, neem contact op</template>
          </v-select>
        </div>
        <div
          class="flex justify-center col-start-6 col-end-8 md:col-start-12 md:col-end-13 xl:col-start-10 xl:col-end-11 pt-4 lg:pt-0"
        >
          <nuxt-link
            v-if="selectedOne !== '' && selectedTwo !== ''"
            :to="determineRedirect(selectedOne, selectedTwo)"
            class="btn-square text-xl lg:text-4xl w-12 h-12 lg:w-24 lg:h-24"
            aria-label="Zoeken"
          >
            <font-awesome-icon :icon="['fas', 'arrow-right']" size="sm"/>
          </nuxt-link>
          <span
            v-else
            class="btn-square text-xl lg:text-4xl w-12 h-12 lg:w-24 lg:h-24"
            style="opacity: 0.8;"
          >
            <font-awesome-icon :icon="['fas', 'arrow-right']" size="sm"/>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      selectedOne: '',
      selectedTwo: '',
      matchSlugs: [],
    };
  },
  computed: {
    ...mapState({
      products: state => state.products,
    }),
    productListOne() {
      return this.products.filter(product => product.title !== this.selectedTwo.title);
    },
    productListTwo() {
      return this.products.filter(product => product.title !== this.selectedOne.title);
    },
  },
  created() {
    this.fetchMatchSlugs();
  },
  methods: {
    determineRedirect(firstOption, secondOption) {
      if (firstOption && secondOption) {
        const slugOne = firstOption.title.replace(/\s+/g, '-').toLowerCase();
        const slugTwo = secondOption.title.replace(/\s+/g, '-').toLowerCase();

        const match = this.matchSlugs.filter(slug => slug.includes(slugOne) && slug.includes(slugTwo))[0];

        if (match && match.length > 0) {
          return {name: 'koppeling-slug', params: {slug: match}};
        }
      }
      return {name: 'koppeling'};
    },
    async fetchMatchSlugs() {
      const {data} = await this.$axios.get('/page/matchslugs');
      this.matchSlugs = data;
    },
  },
};
</script>

<style lang="sass" scoped>
.card
  background-image: url("~assets/img/bg-blok.svg")

.line
  &:before
    position: absolute
    content: ''
    bottom: 44px
    left: calc(50% - 2px)
    border-left: 4px dotted #e95b1b
    height: 12vh
    @media(min-width: theme('screens.sm'))
      height: 8vh

.select
  ::v-deep.vs__dropdown-option--highlight
    width: 120%
</style>
