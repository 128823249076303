// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/match/oranje-bubble.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/img/match/blauwe-bubble.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".bg-l[data-v-22fe2620],.bg-r[data-v-22fe2620]{position:relative}.bg-l[data-v-22fe2620]:before{position:absolute;content:\"\";top:22%;bottom:40%;left:-25%;right:0;z-index:-1;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain}@media (min-width:640px){.bg-l[data-v-22fe2620]:before{top:45%;bottom:15%;left:-12%;right:0}}@media (min-width:769px){.bg-l[data-v-22fe2620]:before{top:50%;bottom:-8%;left:-6%;right:0}}.bg-r[data-v-22fe2620]:after{position:absolute;content:\"\";top:22%;bottom:0;left:58%;right:-16%;z-index:-1;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:contain}@media (min-width:769px){.bg-r[data-v-22fe2620]:after{top:50%;bottom:-8%;left:76%;right:-10%}}.bg-sl[data-v-22fe2620],.bg-sr[data-v-22fe2620]{position:relative}.bg-sl[data-v-22fe2620]:before{position:absolute;content:\"\";top:22%;bottom:40%;left:-25%;right:0;z-index:-1;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain}@media (min-width:640px){.bg-sl[data-v-22fe2620]:before{display:none}}.bg-sr[data-v-22fe2620]:after{position:absolute;content:\"\";top:22%;bottom:0;left:58%;right:-16%;z-index:-1;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:contain}@media (min-width:640px){.bg-sr[data-v-22fe2620]:after{display:none}}", ""]);
// Exports
module.exports = exports;
