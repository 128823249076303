// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/bg-blok.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".card[data-v-34546c84]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.line[data-v-34546c84]:before{position:absolute;content:\"\";bottom:44px;left:calc(50% - 2px);border-left:4px dotted #e95b1b;height:12vh}@media (min-width:640px){.line[data-v-34546c84]:before{height:8vh}}.select[data-v-34546c84] .vs__dropdown-option--highlight{width:120%}", ""]);
// Exports
module.exports = exports;
