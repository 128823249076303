<template>
  <section>
    <div v-if="$route.name !== 'sectoren-id'" :class="$route.name === 'index' ? '-my-40 sm:-my-56 lg:-my-64' : '-my-56'" class="before-bg"/>
    <div :class="$route.name !== 'koppelingen-id' ? 'pt-64 md:pt-96 pb-72 ' : 'xs:pt-40 sm:pt-48 md:pt-32 pb-64'" class="usps relative flex flex-col justify-center items-center lg:py-0">
      <div class="container grid grid-cols-1 lg:grid-cols-4 gap-10 lg:text-center text-white px-16 lg:px-0 z-10">
        <div v-for="(usp, i) in usps" :key="usp.title" class="flex flex-col">
          <img :src="icons[i]" :alt="usp.title" class="hidden lg:block h-40">
          <h4 class="text-heading uppercase py-3" v-text="usp.title"/>
          <p class="leading-loose" v-text="usp.text"/>
        </div>
      </div>
      <section class="hidden md:block w-full">
        <div class="absolute first-animation">
          <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
        </div>
        <div class="absolute second-animation">
          <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
        </div>
        <div class="absolute third-animation">
          <img :src="require('~/assets/img/cloud.svg')" alt="" aria-hidden="true">
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data: () => ({
    icons: [require('~/assets/img/usps/bars.svg'), require('~/assets/img/usps/circlediagram.svg'), require('~/assets/img/usps/connect.svg'), require('~/assets/img/usps/lookingglass.svg')],
  }),
  computed: {
    ...mapState({
      usps: state => state.usps,
    }),
  },
};
</script>

<style lang="sass" scoped>
.before-bg
  height: 90vh
  background: url("~assets/img/bg-linkrow.svg") no-repeat top
  background-size: cover

.usps
  background: url("~assets/img/bg-usps.svg") no-repeat center
  background-size: cover
  @media(min-width: theme('screens.sm'))
    height: 92vh
  @media(min-width: theme('screens.md'))
    height: 145vh
  @media(min-device-width: theme('screens.sm')) and (max-device-width: theme('screens.lg')) and (orientation: landscape)
    height: 145vh
  @media(min-width: theme('screens.xxl'))
    height: 116vh

</style>
