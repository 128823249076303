// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/bg-linkrow.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/img/bg-usps.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".before-bg[data-v-cc80dcd6]{height:90vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;background-size:cover}.usps[data-v-cc80dcd6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:cover}@media (min-width:640px){.usps[data-v-cc80dcd6]{height:92vh}}@media (min-width:769px){.usps[data-v-cc80dcd6]{height:145vh}}@media (min-device-width:640px) and (max-device-width:1025px) and (orientation:landscape){.usps[data-v-cc80dcd6]{height:145vh}}@media (min-width:1600px){.usps[data-v-cc80dcd6]{height:116vh}}", ""]);
// Exports
module.exports = exports;
