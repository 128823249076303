<template>
  <section class="container px-0 lg:px-3 py-10 lg:py-20 relative z-10">
    <div
      :class="[
        {'bg-l': !reverse && $route.name === 'koppelingen-id'},
        {'bg-r overflow-hidden sm:overflow-visible': reverse && $route.name === 'koppelingen-id'},
        {'bg-sl': reverse && $route.name === 'sectoren-id'},
        {'bg-sr overflow-hidden': !reverse && $route.name === 'sectoren-id'},
      ]"
      class="grid lg:grid-cols-5 grid-rows-auto"
    >
      <img
        :src="imageUrl"
        alt="Datalead"
        :class="!reverse ? 'col-start-1 col-end-13 lg:col-start-1 lg:col-end-4' : 'col-start-1 col-end-13 lg:col-start-3 lg:col-end-6'"
        class="w-full"
      >
      <div :class="!reverse ? 'col-start-1 col-end-13 row-start-2 lg:col-start-4 lg:col-end-6 lg:row-start-1' : 'col-start-1 col-end-13 row-start-2 lg:col-start-1 lg:col-end-3 lg:row-start-1 lg:text-right'" class="flex flex-col justify-center px-4 lg:px-10">
        <h2 v-text="title"/>
        <div class="pt-3 pb-4 leading-relaxed" v-html="content"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="sass" scoped>
.bg-l,
.bg-r
  position: relative

.bg-l::before
  position: absolute
  content: ''
  top: 22%
  bottom: 40%
  left: -25%
  right: 0
  z-index: -1
  background: url("~assets/img/match/oranje-bubble.svg") no-repeat
  background-size: contain
  @media(min-width: theme('screens.sm'))
    top: 45%
    bottom: 15%
    left: -12%
    right: 0
  @media(min-width: theme('screens.md'))
    top: 50%
    bottom: -8%
    left: -6%
    right: 0

.bg-r::after
  position: absolute
  content: ''
  top: 22%
  bottom: 0
  left: 58%
  right: -16%
  z-index: -1
  background: url("~assets/img/match/blauwe-bubble.svg") no-repeat
  background-size: contain
  @media(min-width: theme('screens.md'))
    top: 50%
    bottom: -8%
    left: 76%
    right: -10%

.bg-sl,
.bg-sr
  position: relative

.bg-sl::before
  position: absolute
  content: ''
  top: 22%
  bottom: 40%
  left: -25%
  right: 0
  z-index: -1
  background: url("~assets/img/match/oranje-bubble.svg") no-repeat
  background-size: contain
  @media(min-width: theme('screens.sm'))
    display: none

.bg-sr::after
  position: absolute
  content: ''
  top: 22%
  bottom: 0
  left: 58%
  right: -16%
  z-index: -1
  background: url("~assets/img/match/blauwe-bubble.svg") no-repeat
  background-size: contain
  @media(min-width: theme('screens.sm'))
    display: none
</style>
